import axios from 'axios'
import {removeToken, getToken} from '@/util/auth'
import qs from 'qs'
import Dialog from 'quasar/src/plugins/Dialog.js';
import router from "@/router/index";
import Notify from 'quasar/src/plugins/Notify.js';

axios.defaults.timeout = 30000
// 返回其他状态吗
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500 // 默认的
}
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true


// HTTPrequest拦截
 //axios.defaults.baseURL = "http://localhost:8090/dev"
 // axios.defaults.baseURL = "https://www.l4d2love.com/api";
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use(config => {
    const token = getToken();
    config.headers['Auth'] = token// token

    // // headers中配置serialize为true开启序列化
    // if (config.method === 'post' && config.headers.serialize) {
    //   config.data = qs.stringify(config.data);
    //   delete config.data.serialize
    // }

    if (config.method === 'get') {
        config.paramsSerializer = function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }
    return config
}, error => {
    return Promise.reject(error)
})

// // HTTPresponse拦截
axios.interceptors.response.use(res => {
    const status = Number(res.status) || 200
    const message = res.data.msg

    // 后台定义 424 针对令牌过去的特殊响应码
    let code = res.data.code;
    if (code === 100) {
        Dialog.create({
            title: '登录过期',
            message: '令牌状态已过期，请重新登录!',
            cancel: false,
            persistent: true
        }).onOk(roomId => {
            removeToken();
             //window.location = "http://localhost:8090/#/login"
             window.location = process.env.VUE_APP_LOGIN_API
        })
        return
    }

    if (status !== 200 && res.data.msg != null) {
        Notify.create({
            type: 'negative',
            message: res.data.msg,
            position: 'center'
        })
    }

    if (status === 500) {
        Notify.create({
            type: 'negative',
            message: '与服务器的通信失败!请刷新页面重试',
            position: 'center'
        })
        return;
    }

    return res
}, error => {
    // 处理 503 网络异常
    if (error.response.status === 503) {
        return;
    }

    return Promise.reject(new Error(error))
})

export default axios
